import React, { useEffect, useState } from "react";
import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import { NavLink , Link} from "react-router-dom";
import{getCmsContent} from "../actions/axioss/cms.axios"
import { MdKeyboardArrowRight } from 'react-icons/md';

export default function ContactUs(){

const [contact,Setcontactus]=useState([])
   useEffect(()=>
   {
    Contactus();
   },[])
    const Contactus =async()=>{
   
        var resp = await getCmsContent("contactus");
        // console.log("cmslist",resp.data)
        if(resp?.status)
        Setcontactus(resp.data);
    }
    return(
        <>
            <Header />
                <div className="privacy_policy">
                <div className="tf-container">
                <div className="row">
                    <section className="tf-page-title">
                        <div className="col-md-12">
                            <ul className="breadcrumbs" data-aos="fade-right">
                                <li><NavLink to="/">Home <MdKeyboardArrowRight/></NavLink></li>
                                <li>contactUs</li>
                            </ul>
                            <h4 className="page-title-heading" data-aos="fade-left">
                            contactUs                            </h4>
                            
                            </div>
                        </section>
                    </div>
                    <div className="tf-add-nft" data-aos="fade-right">
                        <div className="content" data-aos="zoom-in">
                            <div dangerouslySetInnerHTML={{ __html: contact?.answer }}></div>
                        </div>
                    </div>
                </div>
                </div>
                
            <Footer />
        </>
    )
}
