import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/Modal';
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useContractProviderHook from "./../../actions/contractProviderHook";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isEmpty, NumANdDotOnly, NumberOnly } from "../../actions/common";
import { BidApprove, GetNftCookieToken } from "../../actions/axioss/nft.axios";
import config from '../config/config'
import Select from 'react-select'
import { network } from '../config/network';
export function Bid({ OpenPopup, bidder, closePop, bid, owner, item , onhide}) {
  const { currency } = useSelector(state => state.LoginReducer)
  const { web3, accountAddress, coinBalance } = useSelector(state => state.LoginReducer.AccountDetails);
  const { buyerFees } = useSelector(state => state.LoginReducer.ServiceFees);
  const ContractCall = useContractProviderHook()
  const push = useNavigate()
  const { payload } = useSelector(state => state.LoginReducer.User)
  const { Network } = useSelector((state) => state.LoginReducer)
  const [Btn, SetBtn] = useState('start')
  const [Error, SetError] = useState({})
  const [TokenQuantity, SetTokenQuantity] = useState('1')
  const [TokenBal, SetTokenBal] = useState(0)
  const [show7, setShow7] = useState(true);
const [ Once , setOnce] = useState(true)
  const handleClose7 = () => setShow7(false);
  console.log('hgdfhdshh', owner)
  const [selectedOption, setSelectedOption] = useState(null);
  const [FormValue, SetFormValue] = useState({
    TokenBidderAddress: accountAddress,
    // TokenOwner_Name   : owner.ProfileUrl,
    Category: item.Category,
    NFTQuantity: isEmpty(bidder) ? 1 : bidder.NFTQuantity,
    TokenBidAmt: isEmpty(bidder) ? 0 : bidder.TokenBidAmt,
    NFTId: item.NFTId,
    ContractAddress: item.ContractAddress,
    ContractType: item.ContractType,
    CollectionNetwork: item.CollectionNetwork,
    CoinName: (isEmpty(owner?.CoinName) || owner?.PutOnSaleType != "TimedAuction") ?
      isEmpty(bidder) ?
        currency?.filter(item => item.address != config.DEADADDRESS)?.length > 0 ?
          currency.filter(item => item.address !== config.DEADADDRESS)[0].label
          : 'WBNB'
        : bidder.CoinName : owner?.CoinName
  })
  // console.log("FormValue",FormValue)

  useEffect(() => {
    BalCal(FormValue.CoinName)
  }, [])

  const BalCal = async (data) => {
    let TokenBal = await ContractCall.Token_Balance_Calculation(Token_details.token_address, accountAddress)
    console.log('====================================TokenBal');
    console.log(TokenBal);
    console.log('====================================');
    SetTokenBal(TokenBal)
  }

  const Token_details = useMemo(() => {
    var data = currency?.filter(item => item.label === FormValue.CoinName)?.pop() ?? currency?.filter(item => item.label !== "BNB")?.pop()

    return {
      decimal: data?.decimal ?? 18,
      token_address: data?.address ?? config.DEADADDRESS
    }
  }, [FormValue.CoinName])

  const YouWillGet = useMemo(() => { return ContractCall.buy_bid_price_calculation((FormValue.TokenBidAmt * FormValue.NFTQuantity).toString(), Token_details.decimal.toString()) }, [FormValue.TokenBidAmt, FormValue.NFTQuantity])

  const Validation = async () => {
    // console.log('validddddd',(Number(FormValue.NFTQuantity) % 1 !== 0),FormValue,FormValue.TokenBidAmt <= Number(owner.NFTPrice))
    var Error = {}
    if (isEmpty(FormValue.TokenBidAmt)) Error.TokenBidAmt = "Must Enter Bid Amount"
    if (isEmpty(FormValue.NFTQuantity)) Error.NFTQuantity = "Must Select Atleast One Token"
    else if (Number(owner.NFTBalance) < Number(FormValue.NFTQuantity)) Error.NFTQuantity = "Token Quantity Must be less than token Available"
    else if (Number(FormValue.NFTQuantity) % 1 !== 0) Error.NFTQuantity = "Token Quantity Must be a Valid Count"
    if (ContractCall.Contract_Base_Validation()) Error.Wal = await ContractCall.Contract_Base_Validation()
    if (!isEmpty(bid)) {
      if (FormValue.TokenBidAmt <= bid.TokenBidAmt) Error.TokenBidAmt = "Must Be greater Than " + bid.TokenBidAmt
    }
    else if (owner?.PutOnSaleType === "TimedAuction") {
      if (FormValue.TokenBidAmt < Number(owner.NFTPrice)) Error.TokenBidAmt = "Minimum Bid is " + owner.NFTPrice
    }
    if (TokenBal <= 0) Error.TokenBal = "Not Enough token in your Wallet"
    return Error
  }

  const FormSubmit = async () => {
    const id = toast.loading('Approving Token on processing')
    SetError({})
    SetBtn('process')
    var error = await Validation()
    // console.log('RFGSFGRG',error)
    if (!isEmpty(error)) {
      setTimeout(() => {
      toast.update(id, { render: Object.values(error)[0], type: 'error', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
        
      }, 1000);
      SetBtn('error')
      SetError(error)
    }
    else {
      let allow = web3.utils.fromWei((await ContractCall.allowance_721_1155(Token_details.token_address, accountAddress)) ? String(await ContractCall.allowance_721_1155(Token_details.token_address, accountAddress)) : '0')
      // console.log('fhfhfa',Token_details,accountAddress,Number(allow))
      console.log("YouWillGet", YouWillGet, allow, String(Number(YouWillGet) + Number(allow)));
      let cont = await ContractCall.approve_721_1155(Token_details.token_address, network[Network].tradeContract, web3.utils.toWei(String(Number(YouWillGet) + Number(allow))))
      console.log('====================================');
      console.log("APProve data");
      console.log('====================================');
      if (cont) {

        var _data = FormValue
        _data.HashValue = cont.HashValue
        // _data.TokenOwner = owner.TokenOwner
        _data.from = isEmpty(bidder) ? 'Bid' : 'Edit'
        _data.activity = isEmpty(bidder) ? 'Bid' : 'Edit'
        _data.EmailId = payload.EmailId
        _data.click = `${config.FRONT_URL}/info/${item.CollectionNetwork}/${item.ContractAddress}/${owner.NFTOwner}/${owner.NFTId}`

        var Resp = await BidApprove(_data)
        console.log("BACKAPPROVE", Resp);
        if (Resp.success == 'success') {
          toast.update(id, { render: 'The bid is successfully placed', type: 'success', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
          // SetBtn('done')
          // closePop();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          // push(`/my-item/${payload?.CustomUrl}`)
        }
        else {
          toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
          SetBtn('try')
        }
      }
      else {
        toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })
        SetBtn('try')
      }

    }
  }

  const onChange = async (e, data) => {
    let oii = (data === "price") ? e : e.target
    SetBtn('start')
    const { value, id, name } = oii
    let val = (data === "price") ? "CoinName" : id
    SetFormValue({ ...FormValue, ...{ [val]: data === "inp" ? (name == "NumDotOnly" ? NumANdDotOnly(value) : NumberOnly(value)) : value } })
    if (data === "price") {
      BalCal(value)
    }
  }

  useEffect(() => {
    BalanceCheck();
  }, [item, owner]);

  async function BalanceCheck() {
    SetBtn('process')
    if(Once){
      let Nftbalance = await ContractCall.Current_NFT_Balance(owner, item);
      console.log("ownneerrsnftbusdasdynowbalittemmm", Nftbalance , owner?.NFTBalance , Nftbalance , owner?.NFTOwner , item.ContractType  , owner?.NFTOwner);
      
      if ((Number(Nftbalance) != Number(owner?.NFTBalance) &&  item.ContractType == '1155' ) || ( Nftbalance.toLowerCase() !=  owner?.NFTOwner.toLowerCase()  &&  item.ContractType == '721') ) {
       
  
        toast.warning("You won't Bid  at this moment please refresh you data");
        setTimeout(() => {
          push("/");
        }, 1000);
      }
     
    }
    SetBtn('start')
    return ()=>{ setOnce(false)}
    
  }


  return <Modal
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    show={show7}
  >
    <Modal.Header>
      <button type="button" class="btn-close" aria-label="Close" onClick={ ()=>{ closePop(); 
      onhide();
      }}></button>
      <Modal.Title id="contained-modal-title-vcenter">
        Place a Bid
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className='placeabid'>
        <p className='receives mb-3'>You are about to place a bid for</p>
        <p className='receives mt-3'>{item.NFTName} from <b className='mt-3'>{owner.DisplayName ? owner.DisplayName : owner.NFTOwner}</b></p>
        <div className="minimumbiddropdown mb-4">
          <div className="formgroupsec" >
            <p className="text-left mt-4 mb-2 receives">Enter your bid</p>
            <div className="fixedpricedropdownflex">
              <div className='mb-3'>
                <input
                  type="text"
                  maxLength="7"
                  id="TokenBidAmt"
                  value={FormValue.TokenBidAmt}
                  name="NumDotOnly"
                  onChange={(e) => onChange(e, 'inp')}
                  className=""
                  placeholder="Enter your bid amount"
                  aria-label="bid"
                  aria-describedby="basic-addon2"
                />
              </div>
              {console.log("currency", currency)}
              <div className='mt-3'>
                {console.log("coinname", FormValue.CoinName)}
                <Select
                  value={{ label: FormValue.CoinName, value: FormValue.CoinName }}
                  defaultValue={{ label: FormValue.CoinName, value: FormValue.CoinName }}
                  className="select_custom"
                  onChange={(e) => { onChange(e, 'price') }}
                  options={currency?.filter(item => item.label != "BNB" && item.label != "ETH")}
                  id='CoinName'
                  isSearchable={false}
                  classNamePrefix="react-select"
                  isDisabled={owner?.PutOnSaleType == "TimedAuction"}

                />
              </div>
            </div>
            {/* <p className="pt-3 receives">Bids below this amount won't be allowed. If you not enter any amount we will consider as 0</p> */}
          </div>


        </div>
        {/* {console.log('nftttttt',owner,item)} */}
        <p className="text-left pb-2 receives">Enter Quantity <span>({owner.NFTBalance} available)</span></p>
        <input
          type="text"
          id="NFTQuantity"
          value={FormValue.NFTQuantity}
          onChange={(e) => onChange(e, 'inp')}
          name="NumOnly"
          className=""
          disabled={owner.NFTBalance == '1' || owner.NFTBalance == 1 ? true : false}
          placeholder="Enter your bid quantity"
        />
        <div className="servicefee mt-5 d-flex justify-content-between">
          <p className='points'>Your Balance : </p>
          <p>{web3.utils.fromWei(coinBalance)} {item?.CollectionNetwork}</p>
        </div>
        <div className="servicefee  d-flex justify-content-between">
          <p className='points'>Your Bidding balance :</p>
          <p>{TokenBal} {FormValue.CoinName}</p>
        </div>
        <div className="servicefee  d-flex justify-content-between">
          <p className='points'>Service fee :</p>
          <p>{web3.utils.fromWei(String(buyerFees))}% {FormValue.CoinName}</p>
        </div>
        <div className="servicefee  d-flex justify-content-between">
          <p className='points'>You will Pay :</p>
          <p>{Number(YouWillGet).toFixed(6)} {FormValue.CoinName}</p>
        </div>
        <div className='connecwalletbtns mt-4'>
          {/* <button className="connectwallet secondary">Place a Bid</button> */}
          <Button
            className="connectwallet secondary"
            disabled={Btn == 'error' || Btn === "process" || Btn === "done" ? true : false}
            onClick={Btn == 'start' || Btn === "try" ? FormSubmit : null}
          >
            {Btn == 'start' && (isEmpty(bidder) ? 'Place a bid' : 'Edit Bid')
              || Btn == 'try' && 'Try-Again'
              || Btn == 'error' && 'Error'
              || Btn == 'done' && 'Done'
              || Btn == 'process' && 'In-Progress'
            }
          </Button>
        </div>
      </div>
    </Modal.Body>
  </Modal>
}