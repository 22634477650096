import React, { useEffect, useState, useRef } from "react";
import Card from "./Card.js";
import { NavLink,Link, useParams, useLocation } from "react-router-dom";
import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import { CollectionBySymbol, Token_List_Func } from "../actions/axioss/nft.axios.js";
import Config from '../views/config/config'
import Button from "react-bootstrap/esm/Button.js";
import { useSelector } from "react-redux";
import { Share } from "./seperatemodals/Share";
import config from "./config/config";
import {toast} from 'react-toastify';
import { EditCollectionbyCreator } from "../actions/axioss/nft.axios.js";
import { isEmpty } from "../actions/common.js";
import useContractProviderHook from "../actions/contractProviderHook.js";
import NoData from "./seperatemodals/nodata.jsx";
import { MdKeyboardArrowRight } from 'react-icons/md';

export default function Collection(){
    const [category,setCategory] = React.useState(1);
    const [OpenPopup, SetOpenPopup] = useState('')
    const { CollectionSymbol } = useParams()
    const [TabName, SetTabName] = useState("All");
    const [Tokens, SetTokens] = useState({
    All: { loader: false, page: 1, list: [] },
  });
  const { Categorys } = useSelector((state) => state.LoginReducer);

  const[Collection,setCollection] = useState([])
  const Tabname = (newValue) => {
    SetTabName(newValue);
  };
  var LikeForwardRef = useRef();
  const [LikedTokenList, setLikedTokenList] = useState([]);
  var [cursor,setcursor] = useState(null);
  var [platformcheck,setPlatformCheck] = useState(false)
  const location = useLocation();
  const {state} = location;
  const { payload,isAdmin } = useSelector(state => state.LoginReducer.User);
  const ContractCall = useContractProviderHook();
  useEffect(() => {
    Explore();
    getCollection()
  }, [CollectionSymbol]);
  useEffect(() => {
    if (typeof Tokens[TabName] == "undefined") {
      Tokens[TabName] = { page: 1, list: [], loader: false };
      SetTokens(Tokens);
      Explore(1, TabName);
    } 
  }, [TabName]);

  const getCollection = async (data, tab) => {
    var SendDATA = {CollectionSymbol};
    let Resp = await CollectionBySymbol(SendDATA);
    setCollection(Resp?.msg)
    
  };
  const Explore = async (data, tab) => {    
    // console.log('stttattteeee',state,!isEmpty(state?.CollectionContractAddress))
    if( state?.data?.CollectionContractAddress && state?.data?.CollectionContractAddress !== config.ERC721 && state?.data?.CollectionContractAddress !== config.ERC1155){
      console.log('iffffffff')
      var SendDATA = {CollectionSymbol};
      SendDATA.from = "otherplatform";
      SendDATA.Tabname = "othercollection";
      SendDATA.address = state.data.CollectionContractAddress;
      SendDATA.cursor = cursor;
      let Resp = await CollectionBySymbol(SendDATA);
      if (Resp?.success == 'success' && Resp.data.length > 0) {
        Resp.data.map(async(val)=>{
          console.log('vallluuueeee',val)
          val.owner = await ContractCall.GetOwner((Number(val.type) === 721 ? 'Single' : 'Multiple'), val.colladdress , val.tokenCounts)
        })
        console.log('datttaaaa',Resp)
        setcursor(Resp?.cursor)
        SetTokens({
            ...Tokens, ...{
                [TabName]: {
                    list: [...Tokens[TabName].list, ...Resp.data],
                    loader: ( Resp.data.length ==0 || (SendDATA.TabName == "usercollection" && Resp.cursor == null)) ? false : true,
                    page: Tokens[TabName].page,
                  
                }
            }
        })
    } else 
    SetTokens({
        ...Tokens,
        ...{
          [TabName]: {
            list: Tokens[TabName].list,
            loader: false,
            page: Tokens[TabName].page,
            
          },
        },
      });
    }
    else{
      console.log('elseeeeee')
      var page = data ? data : Tokens[TabName]?.page;
      var SendDATA = {
        TabName: tab ? tab : TabName,
        limit: 4,
        CollectionSymbol,
        page: page ?? 1,
        from: "collection",
      };
      let Resp = await Token_List_Func(SendDATA);
      console.log("Check datad",Resp)
      if (Resp.data) {
        SetTokens({
        ...Tokens,
        ...{
          [TabName]: {
            list: [...Tokens[TabName].list, ...Resp.data],
            loader:
              Resp.Count <= Tokens[TabName].list.length + Resp.data.length ||
              Resp.data.length === 0
                ? false
                : true,
            page: Tokens[TabName].page,
          },
        },
      });
    } else
      SetTokens({
        ...Tokens,
        ...{
          [TabName]: {
            list: Tokens[TabName].list,
            loader: false,
            page: Tokens[TabName].page,
          },
        },
      });
    }
    
  };
  const LoadMore = () => {
    Tokens[TabName].page = Tokens[TabName].page + 1;
    SetTokens(Tokens);
    Explore(Tokens[TabName].page);
  };

  function LikeList(data){
    setLikedTokenList(data)
  }

  const closePop = () => {
    SetOpenPopup("");
  };

  const ImageUpload = async(event)=>{
    const toastupd = toast.loading("You Updated Image")
        var reader = new FileReader()
        const { id, files } = event.target;
        var fileNameExt = files[0].name.substr(files[0].name.lastIndexOf(".") + 1);
        if (event.target.files && event.target.files[0]) {
          if(files,id,files[0].type.includes("image")){
          var file = event.target.files[0];
          var Resp;
          if(id == 'coverupload'){
              Resp = await EditCollectionbyCreator({Type:'cover',symbol:CollectionSymbol,Cover:files[0]})
              // console.log('coverrr',Resp)
          }
          else if(id == "profileupload"){
              Resp = await EditCollectionbyCreator({Type:'profile',symbol:CollectionSymbol,Profile:files[0]})
              // console.log('coverrr',Resp)
          }
          if(Resp?.success=='success'){
            Explore();
            getCollection()
            toast.update(toastupd,{render:Resp.msg,type:'success',isLoading:false,autoClose:700,closeButton:true,closeOnClick:true})
          }
          // let Resp = await userRegister({Type:'cover',CustomUrl:payload.CustomUrl,Cover:files[0]})
          }
          else{
            toast.update(toastupd,{render:"Profile or Cover Image Must be a Image",type:'error',isLoading:false,autoClose:700,closeButton:true,closeOnClick:true})
          }    
      }
  }
 
    return(
        <>
        {/* {console.log('cooolllectiioonn',Collection)} */}
        {console.log('hiiiiiiiiii',Tokens[TabName]?.loader)}
        <Header />
        {OpenPopup === "Share" && (
          <Share
            closePop={closePop}
            title={`${Collection.CollectionName}  Colletion`}
            url={`${config.FRONT_URL}/collection/${Collection.CollectionSymbol}`}
            quote={`${Collection.CollectionName} NFT`}
          />
        )}
        <div className="collection">
            <section className="tf-page-title style-2" data-aos="fade-right">    
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                            <ul className="breadcrumbs">
                                <li><NavLink to="/">Home <MdKeyboardArrowRight/></NavLink></li>
                                <li><NavLink to="/explore">Explore <MdKeyboardArrowRight/></NavLink></li>
                                <li>Collection</li>
                            </ul>
                        </div>
                    </div>
                </div>                    
            </section>

            <section className="tf-banner-collection">
              
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner-collection-inner">
                              
                              {/* {console.log('cobbvverimageee',Collection)} */}
                                <img src={Collection?.CollectionCoverImage
                                            ?`${Config.IMG_URL}/collection/${CollectionSymbol}/${Collection.CollectionCoverImage}`
                                            : require("../assets/images/banner-collection.jpg")} alt="Image" className="img-bg" />
                                <img src={Collection.CollectionProfileImage
                                            ?`${Config.IMG_URL}/collection/${CollectionSymbol}/${Collection.CollectionProfileImage}`
                                            :require("../assets/images/banner.png")} alt="Image" className="img-banner" width="92" height="92" />
                                <div className="button-top">
                                    {/* <Link to="#" className="btn-wishlish"><i className="far fa-heart"></i> Add to Wishlist</Link> */}
                                    <Link to="#" className="btn-collect" onClick={()=>SetOpenPopup("Share")}><i className="far fa-long-arrow-right"></i></Link>
                                    {/* <div className="btn-option">
                                        <i className="far fa-ellipsis-h"></i>
                                        <div className="option_popup show">
                                            <Link to="#">Delete</Link>
                                            <Link to="#">Edit</Link>
                                        </div>
                                    </div> */}
                                    <div className="avatar">
                                <div class="edit_profile">
                                  <input type="file" id="coverupload" name="upload" onChange={(e)=>ImageUpload(e)}/>
                                  <i class="fa fa-pencil"></i>
                                  </div>
                                  </div>
                                </div>
                                  <div className="avatar second">
                                <div class="edit_profile">
                                  <input type="file" id="profileupload" name="upload" onChange={(e)=>ImageUpload(e)}/>
                                  <i class="fa fa-pencil"></i>
                                  </div>
                                  </div>
                            </div>
                            {/* {console.log('coleeeeeee',Collection)} */}
                            <div className="tf-heading style-5">
                                <h2 className="heading" data-aos="fade-right">{Collection.CollectionName}</h2>
                                <p className="sub-heading"  data-aos="fade-down">@{Collection.CollectionSymbol}</p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
                
            <section className="tf-collection-page tf-filter">
                <div className="tf-container">
                    <div className="row ">
                        <div className="col-md-12">
                        <div className="top-menu row"  data-aos="fade-up">
                                <ul className="filter-menu">
                                     <li className="active" ><a>{Collection.Category}</a></li>
                                
                                     {/* <li className= "active"  onClick={() => Tabname(item.label)}><a>{item.label}</a></li> */}
                                </ul>
                                <div id="item_category2" className="dropdown">
                                    <Link to="#" className="btn-selector nolink allshow ">Recently created</Link>
                                    <ul className="show">
                                        <li className="active" onClick={()=>Tabname('NOW')}><span>Recently Created</span></li>
                                        <li  onClick={()=>Tabname('LTH')}><span>Recently Low to High</span></li>
                                        <li  onClick={()=>Tabname('HTL')}><span>Recently High To Low</span></li>
                                        <li  onClick={()=>Tabname('OLD')}><span>Oldest</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                  {console.log('looooadddddd',Tokens)}
                    
                    {Tokens[TabName]?.list.length>0 ?
                    <div className="tf-filter-container row"  data-aos="fade-up">
                        {(
                            Tokens[TabName]?.list.map((item,index) => (
                              <div
                              className="col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber"
                              data-aos="zoom-in-up"
                            >
                            
                        <Card 
                          product={(state?.data?.CollectionContractAddress && state?.data?.CollectionContractAddress !== config.ERC721 && state?.data?.CollectionContractAddress !== config.ERC1155) ?
                          {
                            NFTName : item.name,
                            CollectionNetwork   :  config.COIN_NAME ,
                            ContractAddress :   item.colladdress,
                            NFTOwner    :   Collection.CollectionCreator,
                            NFTId   :   item.tokenCounts,
                            Image   :   item.image,
                            OwnerName : "",
                            OwnerProfile : "" ,
                            type : item.type,
                            amount : item.amount,
                            ipfshash:item.ipfshash,
                            meta:item.meta,
                            metadata:item.metadata,
                            Creator_CustomUrl:payload?.CustomUrl,
                            NFTCreator : Collection.CollectionCreator,
                            CollectionSymbol : CollectionSymbol,
                            Category : Collection.Category,
                            from : "othercollection"
                          }
                          :
                          item} 
                          index={index}
                          LikeList={LikeList}
                          LikedTokenList={LikedTokenList}
                          setLikedTokenList={setLikedTokenList}
                          LikeForwardRef={LikeForwardRef}
                          from={(state?.data?.CollectionContractAddress && state?.data?.CollectionContractAddress !== config.ERC721 && state?.data?.CollectionContractAddress !== config.ERC1155) ? "usercollection" :"collection"}
                        />
                        </div>
                        )))}</div>
                       :<NoData/>
                        }
                    
                    {Tokens[TabName]?.loader && (
                    <div className="col-md-12">
                        <div className="btn-loadmore mt17">
                            <Button className="tf-button loadmore" onClick={LoadMore}>Load More</Button>
                        </div>
                    </div>
                    )}
                </div>
            </section>
        </div>
        <Footer />    
        </>
    )
}