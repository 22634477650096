import React, { useEffect, useState, useRef } from "react";
// import { Link } from "react-router-dom";
import Activity from "./Activity";
import History from "./History";
import Following from "./Following";
import Favourites from "./Favourites";
import EditProfile from "./EditProfile";
import Myitems from "./my_item"
// import Inventory from "../assets/images/svg/activity.svg";
import Inventory from "../assets/images/activity.png";
import Inventory1 from "../assets/images/activityhover.png";

import Followers from "../assets/images/followers.png";
import Followers1 from "../assets/images/followershover.png";

import Followings from "../assets/images/following.png";
import Followings1 from "../assets/images/followinghover.png";
import { MdKeyboardArrowRight } from 'react-icons/md';
import Item from "../assets/images/myitem.png";
import Item1 from "../assets/images/myitemhover.png";

import account from "../assets/images/account.png";
import account1 from "../assets/images/accounthover.png";

import heart from "../assets/images/heart.png";
import heart1 from "../assets/images/hearthover.png";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import HistoryCon from "../assets/images/svg/history.svg";
import FollowingCon from "../assets/images/svg/following.svg";
import Favcon from "../assets/images/svg/fav.svg";
import Edit from "../assets/images/svg/edit.svg";
import { NavLink, useParams, useNavigate, useLocation } from "react-router-dom";
import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import action_config from './config/config';
import { address_showing, isEmpty } from "../actions/common";
import { FollowUnFollowFunc, Token_MyList_Func, userRegister } from "../actions/axioss/user.axios";
import { toast } from 'react-toastify'
import Button from "react-bootstrap/esm/Button";
import { Name_showing} from "../actions/common";
// import { address_showing } from "../actions/common";
import Card from "./Card";
import NoData from "./seperatemodals/nodata";
import config from "./config/config"
import ImportCollection from './seperatemodals/importcollection';

import users from '../assets/images/user.png';
import thumb from '../assets/images/frame.png'
import Referral from "./Referral";
import ReferredUsers from "./referredUsers";

export default function Board() {
    const location = useLocation()
    const { payload } = useSelector(state => state.LoginReducer.User)
    const { accountAddress } = useSelector(state => state.LoginReducer.AccountDetails)
    const { Admin_address,follow} = useSelector(state => state.LoginReducer)
    const [filter, setFilter] = useState('activity');
    var [filters, setFilters] = useState("LatestDrops");
    const [cursor, setcursor] = React.useState('');
    const [tab, SetTab] = useState('owned')
    const [loading, setLoading] = useState(true)
    const [Follow, SetFollow] = useState('follow');
    const [value, SetTabName] = React.useState('owned')
    const { customurl } = useParams()
    const [userProfile, setUserProfile] = useState({})
    const [Tokens, SetTokens] = useState({ 'activity': { 'loader': true, page: 1, list: [] } })
    const [Tokenss, SetTokenss] = useState({ 'owned': { 'loader': true, page: 1, list: [] } })
    var LikeForwardRef = useRef();
    const [LikedTokenList, setLikedTokenList] = useState([]);
    var [loader, setLoader] = useState(true);
    var navigate = useNavigate();
    var [openpopup, setOpenpopup] = useState('');
    console.log('ljfkjfdsdfsdg',location)
    useEffect(() => {

        if (location?.state?.Tab) {

            console.log("locatiojboard ", location, location.state, location.state.Tab);
            setFilter(location.state.Tab)
            SetTabName(location.state.Tab)

        }
        
        if (typeof Tokens[filter] == 'undefined' || Tokens[filter].filter !== filter) {
            Tokens[filter] = { page: 1, list: [], loader: false };
            SetTokens(Tokens);
            Explore(1, filter);
        }
        if (typeof Tokenss[value] == 'undefined' || Tokenss[value].filter !== filters) {
            Tokenss[value] = { page: 1, list: [], loader: false };
            SetTokenss(Tokenss);
            Exploree(1, value);

        }
    }, [value, customurl, filter,location])

console.log("customurl",customurl);
    // useEffect(()=>{
    //    console.log("locatioj ",location,location.state,location.state.Tab);
    //     setFilter(location.state.Tab)
    //     }
    // ,[])


    useEffect(() => {
        getProfileDetails()
        Explore();
        Exploree()
        setFilter('activity')
        // if(accountAddress!==userProfile?.WalletAddress){
        // FollowUnFollow()
        // }
    }, [customurl, accountAddress, userProfile?.WalletAddress,follow ])

    useEffect(()=>{
        getProfileDetails() 
        setFilter(location?.state?.Tab ? location?.state?.Tab  :  'activity')
     

    },[])

    console.log("Admin_address",Admin_address,follow)

    const Exploree = async (data, tab) => {
        var page = data ? data : (Tokenss[value]?.page)
        var SendDATA = {
            TabName: tab ? tab : value,
            limit: (tab === "usercollection" || value === "usercollection") ? 100 : 12,
            CustomUrl: customurl,
            NFTOwner: (userProfile?.WalletAddress ? userProfile?.WalletAddress : accountAddress),
            page: page ?? 1,
            filter: filters,
            from: 'myItem',
            cursor: cursor
        }
        let Resp = await Token_MyList_Func(SendDATA)
        console.log('sfgfhgfs', value, tab, Resp, SendDATA)
        if (Resp?.success == 'success' && Resp.data.length > 0) {

            setcursor(Resp?.cursor)
            SetTokenss({
                ...Tokenss, ...{
                    [value]: {
                        list: [...Tokenss[value].list, ...Resp.data],
                        loader: (Resp.data.length == 0 || (SendDATA.TabName == "usercollection" && Resp.cursor == null)) ? false : true,
                        page: Tokenss[value].page,
                        filter: value,
                    }
                }
            })
            setLoading(false)
        } else
            SetTokenss({
                ...Tokenss,
                ...{
                    [value]: {
                        list: Tokenss[value].list,
                        loader: false,
                        page: Tokenss[value].page,
                        filter: value
                    },
                },
            });
    setLoading(false)
    }


    const getProfileDetails = async () => {
        var SendDATA = {
            CustomUrl: customurl,
            Type: 'getProfile',
            User: payload?.WalletAddress
        }
        var profileInfo = await userRegister(SendDATA)
        console.log('sennwduwidhdddd',profileInfo)
        if (profileInfo?.success == 'success' && profileInfo?.data?.WalletAddress) {
            setUserProfile(profileInfo.data)
            console.log("profuke", profileInfo.data)
            SetFollow(profileInfo.follow == "follow" ? "unfollow" : "follow")
        }
        else {
            const id = toast.loading("Searching User");
            toast.update(id, { render: 'User Not found', type: 'error', isLoading: false, autoClose: 700, closeButton: true, closeOnClick: true })
            setTimeout(function () {
                navigate('/')
            }, 2000);
        }
    }

    const FollowUnFollow = async () => {
        const id = toast.loading(Follow == "follow" ? "You are Following " + Name_showing(userProfile?.DisplayName) : "You are Un Following " + Name_showing(userProfile?.DisplayName))
        let SendData = {
            MyItemAddr: userProfile?.WalletAddress,
            ClickAddr: accountAddress,
            From: 'myitem',
            MyItemCustomUrl: userProfile?.CustomUrl,
            ClickCustomUrl: payload?.CustomUrl,
        }
        // console.log('senddataaa',SendData,userProfile,payload)
        let Resp = await FollowUnFollowFunc(SendData)
        if (Resp?.success) {
            SetFollow(Resp.msg == 'follow' ? 'unfollow' : 'follow')
            toast.update(id, { render: Resp.msg == 'follow' ? 'You are Following ' + Name_showing(userProfile?.DisplayName) + ' Successfully' : 'You are UnFollowing ' +Name_showing(userProfile?.DisplayName) + ' Successfully', type: 'success', isLoading: false, autoClose: 1000 })
        }
        else {
            toast.update(id, { render: 'Try Again', type: 'error', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })

        }
    }

    const Explore = async (data, tab) => {
        var page = data ? data : (Tokens[filter]?.page)
        var SendDATA = {
            TabName: tab ? tab : filter,
            limit: 12,
            CustomUrl: userProfile?.CustomUrl,
            NFTOwner: userProfile?.WalletAddress,
            page: page ?? 1,
            from: 'myItem',
            filter: 'LatestDrops',
        }
        let Resp = await Token_MyList_Func(SendDATA)
        setLoader((Resp?.data?.length == 0 || (SendDATA?.TabName == "usercollection" && Resp?.cursor == null)) ? false : true)
        if (Resp?.success == 'success' && Resp?.data?.length > 0) {

            SetTokens({
                ...Tokens, ...{
                    [filter]: {
                        list: SendDATA.page == 1 ? [...Resp?.data] : [...Tokens[filter]?.list, ...Resp?.data] ,
                        loader: (Resp?.data?.length == 0 || (SendDATA?.TabName == "usercollection" && Resp?.cursor == null)) ? false : true,
                        page: Tokens[filter]?.page
                    }
                }
            })
        }
    }

    const LoadMore = () => {
        Tokens[filter].page = Tokens[filter].page + 1;
        SetTokens(Tokens);
        Explore(Tokens[filter].page);

    }

    const CoverImg = async (event) => {
        const toastupd = toast.loading("You Updated Image")
        var reader = new FileReader()
        const { id, files } = event.target;
        var fileNameExt = files[0].name.substr(files[0].name.lastIndexOf(".") + 1);
        if (event.target.files && event.target.files[0]) {
            if (files, id, files[0].type.includes("image")) {
                var file = event.target.files[0];
                var Resp;
                if (id == 'coverupload') {
                    Resp = await userRegister({ Type: 'cover', WalletAddress: payload.WalletAddress, Cover: files[0] })
                    // console.log('coverrr',Resp)
                }
                else {
                    Resp = await userRegister({ Type: 'profileimage', WalletAddress: payload.WalletAddress, Profile: files[0] })
                    // console.log('coverrr',Resp)
                }
                if (Resp?.success == 'success') {
                    window.location.reload()
                    getProfileDetails();
                    toast.update(toastupd, { render: Resp.msg, type: 'success', isLoading: false, autoClose: 700, closeButton: true, closeOnClick: true })
                    window.location.reload()
                }
                // let Resp = await userRegister({Type:'cover',CustomUrl:payload.CustomUrl,Cover:files[0]})
            }
            else {
                toast.update(toastupd, { render: "Profile or Cover Image Must be a Image", type: 'error', isLoading: false, autoClose: 700, closeButton: true, closeOnClick: true })
            }
        }

        // let formData = { ...formValue, ...{ [id]: files[0] } };
    }

    function LikeList(data) {
        setLikedTokenList(data)
    }

    console.log("userProfile",userProfile);

    return (
        <>
            <Header />
            {/* {console.log('photooooo',Tokens,loader)} */}
            <div className="board">
                <section className="tf-page-title">
                    <div className="tf-container">
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="breadcrumbs" data-aos="fade-right">
                                    <li><NavLink to="/">Home <MdKeyboardArrowRight/></NavLink></li>
                                    <li>Profile</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="thumb-pagetitle">
                                {/* {console.log('addresss',userProfile,payload)} */}
                                <img src={userProfile?.Cover ? action_config.IMG_URL + "/user/" + userProfile?.WalletAddress + '/cover/' + userProfile?.Cover : thumb} />
                                {userProfile?.WalletAddress === payload?.WalletAddress &&
                                    <div className="edit_profile">
                                        <input type="file" id="coverupload" name="upload" onChange={(e) => CoverImg(e)} />
                                        <i className="fa fa-pencil"></i>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="tf-dashboard tf-tab">
                    <div className="tf-container">
                        <div className="row ">
                            <div className="col-xl-3 col-lg-12 col-md-12" data-aos="fade-up">
                                <div className="dashboard-user">
                                    <div className="dashboard-infor">
                                        <div className="avatar">
                                            <img src={userProfile?.Profile ? action_config.IMG_URL + "/user/" + userProfile?.WalletAddress + '/profile/' + userProfile?.Profile : config.profile} alt="images" />
                                            {userProfile?.WalletAddress === payload?.WalletAddress &&
                                                <div className="edit_profile">
                                                    <input type="file" id="profileupload" name="upload" onChange={(e) => CoverImg(e)} />
                                                    <i className="fa fa-pencil" style={{ color: "#000" }}></i>
                                                   
                                                </div>}
                                                {/* <div className="pax">{userProfile?.DisplayName}</div>
                                        <div className="description">
                                            <p>{userProfile?.Bio}</p>
                                        </div> */}
                                        </div>
                                        {/* isEmpty(userProfile?.DisplayName) ?  address_showing(userProfile?.WalletAddress) : Name_showing(userProfile?.DisplayName) */}
                                        {/* <div className="name">{userProfile?.DisplayName}</div> */}
                                        {console.log("NAEM",userProfile?.DisplayName)}
                                        <div className="pax">{userProfile?.DisplayName}</div>
                                        <div className="description">
                                            <p>{userProfile?.Bio}</p>
                                        </div>
                                        {console.log('fgfhgfhfhsfhs',userProfile)}
                                        <ul className="social-item mb-4">
                                    {userProfile.Facebook !== "" && <li><a href={userProfile?.Facebook} target="_blank"><i className="fab fa-facebook-f"></i></a></li> }
                                    {userProfile.Instagram !== "" &&<li><a href={userProfile?.Twitter} target="_blank"><i className="fab fa-twitter"></i></a></li> }
                                    {userProfile.Twitter !== "" &&<li><a href={userProfile?.Instagram} target="_blank"><i className="fab fa-instagram"></i></a></li> }
                                    {userProfile.Youtube !== "" &&<li><a href={userProfile?.Youtube} target="_blank"><i className="fab fa-youtube"></i></a></li> }
                                    </ul>
                                        {/* {console.log("userProfileee",userProfile?.WalletAddress,payload,userProfile)} */}
                                        {userProfile?.WalletAddress != payload?.WalletAddress &&
                                            <div className="text-center">
                                                <Button className="tf-button style-2" onClick={FollowUnFollow}>{Follow}</Button>
                                            </div>}

                                    </div>
                                    <div className="dashboard-filter">
                                        <ul className="filter-menuu menu-tab">
                                            <li className={filter == "activity" ? "active dashboard" : "dashboard"} onClick={() => setFilter("activity")}><Link to="#">
                                                {/* <span className="img1"></span>  */}
                                                <div className="listed_flexing">
                                                <img className="imagesside" src={Inventory} />
                                                <img className="imagesside1" src={Inventory1} />
                                                &emsp;<p>Activity</p>
                                                </div></Link></li>
                                            <li className={filter == 'follower' ? "active dashboard" : "dashboard"} onClick={() => setFilter('follower')}><Link to="#">
                                                {/* <img src={HistoryCon} /> */}
                                                <div className="listed_flexing">
                                                <img className="imagesside" src={Followers} />
                                                <img className="imagesside1" src={Followers1} />
                                                &emsp;<p>Followers</p>
                                                </div></Link></li>
                                            <li className={filter == 'following' ? "active dashboard" : "dashboard"} onClick={() => setFilter('following')}><Link to="#">
                                                {/* <img src={FollowingCon} /> */}
                                                <div className="listed_flexing">
                                                <img className="imagesside" src={Followings} />
                                                <img className="imagesside1" src={Followings1} />
                                                &emsp;<p>Following</p> 
                                                </div></Link></li>
                                            {/* <li className={filter == 'collection' ? "active dashboard" : "dashboard"}onClick={() => setFilter('collection')}><Link to="#">
                                        <img src={require("../assets/images/collection.png")} />
                                        &emsp;Collection </Link></li> */}
                                            {/* {userProfile?.WalletAddress === payload?.WalletAddress && 
                                        <li className={filter == 'importcollection' ? "active dashboard" : "dashboard"}onClick={() => {setFilter('importcollection');setOpenpopup('collection')}}><Link to="#">
                                        <img src={require("../assets/images/collection.png")} />
                                        &emsp;Import Collection </Link></li>} */}
                                            <li className={filter == 'favourite' ? "active dashboard" : "dashboard"} onClick={() => setFilter('favourite')}><Link to="#">
                                                {/* <img src={Favcon} />  */}
                                                <div className="listed_flexing">
                                                <img className="imagesside" src={heart} />
                                                <img className="imagesside1" src={heart1} />
                                                &emsp;<p>Favourites</p>
                                                </div></Link></li>
                                            {userProfile?.WalletAddress === payload?.WalletAddress &&
                                                <li className={filter == 'account' ? "active dashboard" : "dashboard"} onClick={() => setFilter('account')}><Link to="#">
                                                    {/* <img src={Edit} /> */}
                                                    <div className="listed_flexing">
                                                    <img className="imagesside" src={account} />
                                                    <img className="imagesside1" src={account1} />
                                                    &emsp;<p>Account Settings</p>
                                                    </div></Link></li>}
                                            {userProfile?.WalletAddress === payload?.WalletAddress && <li className={filter == "referral" ? "active dashboard" : "dashboard"} onClick={() => setFilter("referral")}><Link to="#">
                                            <div className="listed_flexing">
                                                <img className="imagesside" src={Inventory} />
                                                <img className="imagesside1" src={Inventory1} />
                                                &emsp;<p>Referral</p>
                                                </div></Link></li>}
                                            {userProfile?.WalletAddress === payload?.WalletAddress && <li className={filter == "referredUsers" ? "active dashboard" : "dashboard"} onClick={() => setFilter("referredUsers")}><Link to="#">
                                            <div className="listed_flexing">
                                                <img className="imagesside" src={Inventory} />
                                                <img className="imagesside1" src={Inventory1} />
                                                &emsp;<p>Referred Users</p>
                                                </div></Link></li>}
                                            <li className={filter == 'owned' ? "active dashboard" : "dashboard"} onClick={() => setFilter('owned')}>
                                                <Link to="#">
                                                    {/* <img src={require("../assets/images/MY-ITEMS-ICON.png")} /> */}
                                                    <div className="listed_flexing">
                                                    <img className="imagesside" src={Item} />
                                                    <img className="imagesside1" src={Item1} />
                                                    &emsp;<p>My Items</p>
                                                    </div></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-9 col-lg-12 col-md-12">
                                <div className="dashboard-content inventory content-tab" data-aos="fade-right">

                                    {filter == 'account'
                                        ? (<EditProfile
                                            
                                            GetProfile={getProfileDetails} />)
                                        : filter == "activity"
                                            ? <Activity
                                                datas={Tokens[filter]}
                                                Loadmore={LoadMore}
                                                List={Tokens[filter]}
                                                filter={filter}
                                                Explore={Explore}
                                                SetTokens={SetTokens}
                                                Tokens={Tokens}
                                                userProfile={userProfile}
                                                Loader={loader} />
                                            : (filter == 'following' || filter == 'follower' || filter == "collection")
                                                ? (<Following DisplayName={userProfile.DisplayName} datas={Tokens[filter]?.list} type={filter} user={userProfile?.WalletAddress === payload?.WalletAddress} />)
                                                : filter == "favourite" ?
                                                    <>
                                                        <h3 className="profilename">{userProfile?.DisplayName}</h3>
                                                        <h4 class="title-dashboard aos-init aos-animate" data-aos="fade-right">Favourites</h4>
                                                        <div className="row">
                                                            {/* {console.log('favsssss',Tokens[filter])} */}

                                                            {Tokens[filter]?.list.length > 0 ? Tokens[filter]?.list.map((val, index) =>
                                                                <div
                                                                    className="col-xl-4 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber"
                                                                    data-aos="zoom-in-up"
                                                                >                                           <Card
                                                                        product={val}
                                                                        index={index}
                                                                        LikeList={LikeList}
                                                                        LikedTokenList={LikedTokenList}
                                                                        setLikedTokenList={setLikedTokenList}
                                                                        LikeForwardRef={LikeForwardRef}
                                                                    />
                                                                </div>
                                                            )
                                                                :
                                                                <NoData />
                                                            }
                                                        </div>
                                                    </>
                                                    :
                                                    filter == "referral"
                                                        ? <Referral
                                                            datas={Tokens[filter]}
                                                            Loadmore={LoadMore}
                                                            List={Tokens[filter]}
                                                            filter={filter}
                                                            Explore={Explore}
                                                            SetTokens={SetTokens}
                                                            Tokens={Tokens}
                                                            userProfile={userProfile}
                                                            Loader={loader} />
                                                        :
                                                        filter == "referredUsers"
                                                            ? <ReferredUsers
                                                                datas={Tokens[filter]}
                                                                Loadmore={LoadMore}
                                                                List={Tokens[filter]}
                                                                filter={filter}
                                                                Explore={Explore}
                                                                SetTokens={SetTokens}
                                                                Tokens={Tokens}
                                                                userProfile={userProfile}
                                                                Loader={loader} />
                                                            :
                                                            filter == "owned" &&

                                                            <>
                                                                <h3 className="profilename">{userProfile?.DisplayName}</h3>
                                                                {/* <h3 className="owned-h3">0xf1086c8....b7f5asd95</h3>
                                                        <h6 className="owned-h5">0xf1086c8....b7f5asd95</h6> */}
                                                                <h4 class="title-dashboard titleowned myitemz aos-init aos-animate" data-aos="fade-right">My Items</h4>

                                                                <ul className="filter-menu my-5">
                                                                    <li className={value == "owned" ? "active" : ""} onClick={() => SetTabName("owned")}><a>Owned</a></li>
                                                                    <li className={value == "onsale" ? "active" : ""} onClick={() => SetTabName("onsale")}><a>On sale</a></li>
                                                                    {/* <li className={value == "liked" ? "active" : ""} onClick={() => SetTabName("liked")}><a>Liked</a></li> */}
                                                                    {/* {accountAddress == userProfile.WalletAddress &&   <li className={value == "usercollection" ? "active" : ""} onClick={() => SetTabName("usercollection")}><a>User Collections</a></li>} */}
                                                                </ul>
                                                                <div className="tf-filter-container row" data-aos="fade-up">
                                                                    {console.log('ljdkjgsdlkgsd', Tokenss[value]?.list)}
                                                                    {Tokenss[value] &&
                                                                        Tokenss[value]?.list?.length == 0 ? <>
                                                                        {/* <div className="text-centre">
                                                                            <h3>Loading...</h3>
                                                                            {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /}
                                                                            <p>Nothing for ReSale at this time</p>
                                                                            <div className="load-more">
                                                                                <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link>
                                                                            </div>
                                                                        </div> */}
                                                                        <NoData />
                                                                    </> :
                                                                        Tokenss[value]?.list?.length > 0 ?
                                                                            Tokenss[value].list.map((val, index) =>

                                                                                <div
                                                                                    className="col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber"
                                                                                    data-aos="zoom-in-up"
                                                                                >
                                                                                    {console.log("carddata", val)}
                                                                                    <Card
                                                                                       
                                                                                        product={val}
                                                                                        index={index}
                                                                                        LikeList={LikeList}
                                                                                        LikedTokenList={LikedTokenList}
                                                                                        setLikedTokenList={setLikedTokenList}
                                                                                        LikeForwardRef={LikeForwardRef} />
                                                                                </div>
                                                                            ) : <NoData />}

                                                                    {/* <div className="table-btn">
                                                                    <Link to="#" onClick={()=>LoadMore()}>View More</Link>
                                                                </div> */}

                                                                </div>
                                                                {/* <Tabs
                                                            defaultActiveKey="home"
                                                            transition={false}
                                                            id="noanim-tab-example"
                                                            className="mb-3"
                                                        > */}
                                                                {/* <Tab eventKey="home" title="Owned" onClick={() => {SetTabName("owned")}}> */}
                                                                {/* <div className="allinall"> */}
                                                                {/* <div className="row allinall"> */}
                                                                {/* {console.log('favsssss',Tokens[filter])} */}

                                                                {/* {Tokens[filter]?.list.length > 0 ? Tokens[filter]?.list.map((val, index) =>
                                                                <div
                                                                    className="col-xl-4 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber"
                                                                    data-aos="zoom-in-up"
                                                                >
                                                                    <Card
                                                                        product={val}
                                                                        index={index}
                                                                        LikeList={LikeList}
                                                                        LikedTokenList={LikedTokenList}
                                                                        setLikedTokenList={setLikedTokenList}
                                                                        LikeForwardRef={LikeForwardRef}
                                                                    />
                                                                     
                                                                </div>
                                                            )
                                                                :
                                                                <NoData />
                                                            }
                                                        </div>

                                                        <div class="btn-loadmore wow fadeInUp aos-init aos-animate" data-aos="fade-up">
                                                            <a aria-current="page" class="tf-button style-8 loadmore active viewsmore" href="/naifty">View More</a>
                                                            </div> */}
                                                                {/* </div> */}
                                                                {/* </Tab>
                                                            <Tab eventKey="profile" title="On Sales" onClick={() => SetTabName("onsale")}>
                                                            <div className="row allinall"> */}
                                                                {/* {console.log('favsssss',Tokens[filter])} */}
                                                                {/* 
                                                            {Tokens[filter]?.list.length > 0 ? Tokens[filter]?.list.map((val, index) =>
                                                                <div
                                                                    className="col-xl-4 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber"
                                                                    data-aos="zoom-in-up"
                                                                >
                                                                    <Card
                                                                        product={val}
                                                                        index={index}
                                                                        LikeList={LikeList}
                                                                        LikedTokenList={LikedTokenList}
                                                                        setLikedTokenList={setLikedTokenList}
                                                                        LikeForwardRef={LikeForwardRef}
                                                                    />
                                                                </div>
                                                            )
                                                                :
                                                                <NoData />
                                                            }
                                                        </div>
                                                            </Tab>
                                                            <Tab eventKey="contact" title="Liked" onClick={() => SetTabName("liked")}>
                                                            <div className="row allinall"> */}
                                                                {/* {console.log('favsssss',Tokens[filter])} */}

                                                                {/* {Tokens[filter]?.list.length > 0 ? Tokens[filter]?.list.map((val, index) =>
                                                                <div
                                                                    className="col-xl-4 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber"
                                                                    data-aos="zoom-in-up"
                                                                >
                                                                    <Card
                                                                        product={val}
                                                                        index={index}
                                                                        LikeList={LikeList}
                                                                        LikedTokenList={LikedTokenList}
                                                                        setLikedTokenList={setLikedTokenList}
                                                                        LikeForwardRef={LikeForwardRef}
                                                                    />
                                                                </div>
                                                            )
                                                                :
                                                                <NoData />
                                                            }
                                                        </div>
                                                            </Tab>
                                                        </Tabs> */}

                                                                {/* <div className="row">
                                                         

                                                            {Tokens[filter]?.list.length > 0 ? Tokens[filter]?.list.map((val, index) =>
                                                                <div
                                                                    className="col-xl-4 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber"
                                                                    data-aos="zoom-in-up"
                                                                >
                                                                    <Card
                                                                        product={val}
                                                                        index={index}
                                                                        LikeList={LikeList}
                                                                        LikedTokenList={LikedTokenList}
                                                                        setLikedTokenList={setLikedTokenList}
                                                                        LikeForwardRef={LikeForwardRef}
                                                                    />
                                                                </div>
                                                            )
                                                                :
                                                                <NoData />
                                                            }
                                                        </div> */}
                                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />

        </>
    )
}