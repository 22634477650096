import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import moment from "moment";
import Button from "react-bootstrap/esm/Button";
import ImgAudVideo from "../../separate/ImgAudVideo";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useContractProviderHook from "./../../actions/contractProviderHook";
import { useHistory, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isEmpty, NumANdDotOnly } from "../../actions/common";
import config from "../config/config";
import { CreateOrder } from "../../actions/axioss/nft.axios";
import CLockModal from './ClockModal';


export function Lower({ text, owner, types, closePop, file, type, thumb, item , onhide }) {
  console.log("fsessssssssss",  item);
  const[btn , setbtn] = useState(false)
  const { web3p, accountAddress } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  )
  const [once,setOnce]=useState(true)
  

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#fff"
        : isSelected
        ? "#fff"
        : isHovered
        ? "fff"
        : "#fff",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#06038D"
          : isSelected
          ? "#06038D"
          : isFocused
          ? "#06038D"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      // backgroundColor: "#fff ",
      border: "none",
      color:"#fff",
      borderRadius: 30,
      // marginTop: "10px",
      border: "none",
      outline: "none",
      fontSize: "13px",
      cursor:"pointer"
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 30,
      // backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
      cursor:"pointer"
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      border: "none",
      outline: "none",
      top: 0,
      color: "#fff",
      cursor:"pointer"
      // marginTop: "10px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#fff",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const handleClose8 = () => setShow8(false);
  const [show4, setShow4] = useState(true);
  const [show8, setShow8] = useState(false);
  const handleClose4 = () => setShow4(false);
  const [BtnData, SetBtnData] = useState("start");
  const [TokenBtn, SetTokenBtn] = useState("start");
  const [Mintbtn, SetMintbtn] = useState("start");
  const [dropdown, setdropdown] = useState(false);
  const [dropdown1, setdropdown1] = useState(false);
  const [modal, setModal] = useState("");
  const [OpenPopup, SetOpenPopup] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const { currency } = useSelector((state) => state.LoginReducer);
  const ContractCall = useContractProviderHook();
  const push = useNavigate();
  const { Network } = useSelector((state) => state.LoginReducer)
  console.log("CURRin putonsale", currency)
  const { payload } = useSelector((state) => state.LoginReducer.User);
  const { web3 } = useSelector((state) => state.LoginReducer.AccountDetails);
  const { sellerFees } = useSelector((state) => state.LoginReducer.ServiceFees);

  const [FormValue, SetFormValue] = useState(item);
  const [ValidateError, SetValidateError] = useState({});
  console.log('skgshkdfgds', FormValue)
  useEffect(() => {
    if (!FormValue.CoinName) {
      SetFormValue({
        ...FormValue,
        ...{ ["CoinName"]: currency[0]?.label ?? Network == config.BNBCHAIN ? "BNB" : "ETH" },
      });
    }

    SetFormValue({ ...FormValue, ...{ ["EmailId"]: payload.EmailId } });
  }, []);



  const TokenApproveCall = async () => {
    SetTokenBtn("process");
    const id = toast.loading("Approve Processing");
    const cont = await ContractCall.SetApproveStatus(
      FormValue.ContractType == 721 || FormValue.ContractType == "721"
        ? "Single"
        : "Multiple",
      FormValue.ContractAddress
    );
    toast.update(id, {
      render: cont ? "Approved Successfully" : "Approved Failed",
      type: cont ? "success" : "error",
      isLoading: false,
      autoClose: 1000,
      closeButton: true,
      closeOnClick: true
    });
    if (cont.status) {
      SetTokenBtn("done");
      SetBtnData("process");
    } else SetTokenBtn("try");
  };

  const onSelectChange = (e, data) => {
    SetBtnData("start");

    const id = "CoinName";
    //console("ada", e,id)
    const { label, value } = e;
    SetFormValue({ ...FormValue, ...{ [id]: value } });
  };

  const YouWillGet = useMemo(() => {
    return ContractCall.price_calculation(FormValue.NFTPrice);
  }, [FormValue.NFTPrice]);

  const onChange = (e) => {
    const { files, value, id } = e.target;
    console.log("price@123", value);
    SetBtnData("start");
    // var price = NumANdDotOnly(value)
    // console.log("price@1243",price);
    SetFormValue({ ...FormValue, ...{ [id]: NumANdDotOnly(value) } });
  };

  const FormSubmit = async () => {
    console.log("hehehehehe");
    SetBtnData("start");
    const id = toast.loading("Validating Form");
    var Error = Validation(FormValue);
    console.log('hdfhgdhsh', FormValue, owner, Error)
    SetBtnData("process");
    if (isEmpty(Error)) {
      let Respc = await ContractCall.Contract_Base_Validation();
      if (!Respc) {
        let Statu = await ContractCall.GetApproveStatus(
          FormValue.ContractType == 721 || FormValue.ContractType == "721"
            ? "Single"
            : "Multiple",
          FormValue.ContractAddress
        );
        if (Statu == true) {
          setShow4(false);
          setShow8(true);
          SetBtnData("process");
          toast.update(id, {
            render: "Ready To Place Order",
            type: "success",
            isLoading: false,
            autoClose: 1000,
            closeButton: true,
            closeOnClick: true
          });
        } else {
          setShow4(false);
          setShow8(true);
          SetBtnData("open");
          toast.update(id, {
            render: "Get APProve",
            type: "success",
            isLoading: false,
            autoClose: 1000, closeButton: true, closeOnClick: true
          });
        }
      } else {
        SetBtnData("error");
        SetValidateError(Respc);
      }
    } else {
      toast.update(id, {
        render: "Check Fields",
        type: "error",
        isLoading: false,
        autoClose: 1000, closeButton: true, closeOnClick: true
      });
      SetBtnData("error");
      SetValidateError(Error);
    }
  };
  const closemodal = () => SetBtnData("start");

  const Validation = (data) => {
    let ValidateError = {};
    const { NFTPrice, CoinName, PutOnSaleType, ClockTime, EndClockTime, NFTQuantity, ContractType } = data;
    console.log('errrorrrr', ContractType, ContractType === '1155' || ContractType === 1155, NFTQuantity, owner?.NFTBalance, Number(NFTQuantity) > Number(item?.NFTBalance))
    if (
      (PutOnSaleType == "FixedPrice" || PutOnSaleType == "TimedAuction") &&
      isEmpty(NFTPrice)
    )
      ValidateError.NFTPrice = "Token Price Required";
    if (
      (PutOnSaleType == "FixedPrice" || PutOnSaleType == "TimedAuction") &&
      !CoinName
    )
      ValidateError.CoinName = "CoinName Required";
    if (PutOnSaleType == "TimedAuction" && !ClockTime)
      ValidateError.ClockTime = "ClockTime Required";
    if (PutOnSaleType == "TimedAuction" && !EndClockTime)
      ValidateError.EndClockTime = "EndClockTime Required";
      if (PutOnSaleType == "TimedAuction") {
        if (ClockTime > EndClockTime || ClockTime.toString() == EndClockTime.toString()) {
          ValidateError.EndClockTime = "Time Auction should not be same";
        }
  
        if(new Date(ClockTime) > new Date(EndClockTime)){
          ValidateError.EndClockTime = "Please Enter valid End Time"
        }
      }
    // if (ContractType === '1155' || ContractType === 1155) {
    //   if (Number(NFTQuantity) > Number(owner?.NFTBalance)) {
    //     ValidateError.NFTQuantity = "Quantity Must Be Less Than " + owner?.NFTBalance
    //   }
    // }
    return ValidateError;
  };

  const onChangeTab = (e, newValue) => {
    SetBtnData("start");
    SetFormValue({
      ...FormValue,
      ...{ ["PutOnSaleType"]: newValue, ["FixedPrice"]: "", ["CoinName"]: "" },
    });
  };

  const onSlectDate = (data, up) => {
    SetBtnData("start");
    setdropdown(false);
    setdropdown1(false);
    if (up == "RightAfterClick") {
      var date = new Date().setMinutes(20);
    } else if (up == "one" || up == "two") {
      var date = new Date().setDate(
        up == "one"
          ? 1 + new Date().getDate()
          : up == "two"
            ? 2 + new Date().getDate()
            : 0
      );
    } else setModal(data);

    var formvalue = {
      ...FormValue,
      ...{ [data]: moment(date).format("YYYY-MM-DD HH:mm:ss") },
    };
    SetFormValue(formvalue);
  };

  const setClockValue = (data, date) => {
    console.log("DDATE,Data", data, date)
    SetFormValue({
      ...FormValue,
      ...{ [data]: new Date(date) }
        // moment(date).format("YYYY-MM-DD HH:mm:ss") },
    });
  };
  const startingdate = [
    { value: "List Immediately", label: "List Immediately" },
    { value: "Scheduled Listing", label: "Scheduled Listing" },
  ];
  const enddate = [
    { value: "1 day", label: "1 day" },
    { value: "2 days", label: "2 days" },
    { value: "Scheduled Listing", label: "Scheduled Listing" },
  ];
  async function ListCall() {
    
    const id = toast.loading("Listing Processing");
    SetMintbtn("process");
    if (FormValue.PutOnSaleType == "FixedPrice") {
      var error = await ContractCall.Contract_Base_Validation();
      console.log('====================================');
      console.log(error,FormValue);
      console.log('====================================');
      if (error)
        toast.update(id, {
          render: error,
          type: "error",
          isLoading: false,
          autoClose: 1000, closeButton: true, closeOnClick: true
        });
      else {
        const cont = await ContractCall.place_order_721_1155(
          owner.NFTId,
          web3.utils.toWei(FormValue.NFTPrice?.toString()),
          FormValue.ContractAddress,
          Number(FormValue.ContractType),
          "data"
        );
        console.log("cont", cont)
        
        if (cont) {
          let _data = FormValue;
          _data.NFTOwner = payload.WalletAddress;
          _data.HashValue = cont.HashValue;
          _data.NFTId = owner.NFTId;
          _data.activity = "PutOnSale";
          _data.NFTBalance = owner?.NFTBalance
          _data.click = `${config.FRONT_URL}/info/${FormValue.CollectionNetwork}/${FormValue.ContractAddress}/${owner.NFTOwner}/${owner.NFTId}`;
          console.log("_datattatataa", _data);
          _data.EmailId = payload?.EmailId
          BackCall(id, _data);
        } else {
          console.log("json fil")
          toast.update(id, {
            render: "Transaction Failed",
            type: "error",
            isLoading: false,
            autoClose: 1000, closeButton: true, closeOnClick: true
          });
          console.log("ewjewkljelwjrkwejkrweklr")
          SetMintbtn("try");
        }
      }
    } else {
      let _data = FormValue;
      _data.NFTOwner = payload.WalletAddress;
      _data.HashValue = "";
      _data.NFTId = owner.NFTId;
      _data.activity = "PutOnSale";
      _data.click = `${config.FRONT_URL}/info/${FormValue.CollectionNetwork}/${FormValue.ContractAddress}/${owner.NFTOwner}/${owner.NFTId}`;
      console.log("_datattatataa", _data);
      _data.EmailId = payload?.EmailId
      BackCall(id, _data);
    }
  }


  var validStart = function (current) {
    var yesterday = moment().subtract(1, 'day')
    //return current.isAfter(new Date())&&current.isBefore(new Date(EndClocktime));
    return current.isAfter(yesterday);
  }
  var validEnd = function (current) {
    return current.isAfter(FormValue.ClockTime ? new Date(FormValue.ClockTime) : undefined);
  }
  const CloseModal = () => {
    SetOpenPopup("")
  }
  const DateSelection = (e, data) => {
    console.log('datttteeeee',moment(new Date()).format("YYYY-MM-DD h:mm:ss a"))
    SetBtnData('start')
    if (data == "start") {
      if (e.value == "List Immediately")
        SetFormValue({
          ...FormValue,
          ...{
            ["ClockTime"]: new Date(),
          },
        });
      else if (e.value == "Scheduled Listing") SetOpenPopup("ClockTime");
    } else {
      if (e.value == "1 day") {
        console.log('dateeeee', new Date(new Date(FormValue.ClockTime).setDate(new Date(FormValue.ClockTime).getDate() + 1)))
        if (FormValue.ClockTime === "") {
          SetFormValue({
            ...FormValue,
            ...{
              ["EndClockTime"]: 
              // moment(
                new Date(new Date().setDate(new Date().getDate() + 1))
              // ).format("YYYY-MM-DD h:mm:ss a"),
            },
          });
        }
        else {
          SetFormValue({
            ...FormValue,
            ...{
              ["EndClockTime"]:
              //  moment(
                new Date(new Date(FormValue.ClockTime).setDate(new Date(FormValue.ClockTime).getDate() + 1))
              // ).format("YYYY-MM-DD h:mm:ss a"),
            },
          });
        }
      } else if (e.value == "2 days") {

        if (FormValue.ClockTime === "") {
          SetFormValue({
            ...FormValue,
            ...{
              ["EndClockTime"]:
              //  moment(
                new Date(new Date().setDate(new Date().getDate() + 2))
              // ).format("YYYY-MM-DD h:mm:ss a"),
            },
          });
        }
        else {
          SetFormValue({
            ...FormValue,
            ...{
              ["EndClockTime"]: 
              // moment(
                new Date(new Date(FormValue.ClockTime).setDate(new Date(FormValue.ClockTime).getDate() + 2))
              // ).format("YYYY-MM-DD h:mm:ss a"),
            },
          });
        }
      }
      else if (e.value == "Scheduled Listing") SetOpenPopup("EndClockTime");
    }
  };
  const BackCall = async (id, _data) => {
   
    let Resp = await CreateOrder(_data);
    
    if (Resp.success == "success") {
      toast.update(id, {
        render: "The NFT is successfully listed for sale ",
        type: Resp.success,
        isLoading: false,
        autoClose: 1000, closeButton: true, closeOnClick: true
      });
      // SetMintbtn("done");
      push("/profile/" + payload.CustomUrl, { state: { Tab: 'owned' } });
    } else {
      console.log("dsahgdhasgjgdjasd")
      toast.update(id, {
        render: "Transaction Failed",
        type: "error",
        isLoading: false,
        autoClose: 1000, closeButton: true, closeOnClick: true
      });
      SetMintbtn("try");
    }
  };


  useEffect(() => {
    BalanceCheck();
  }, [item, owner]);

  async function BalanceCheck() {
    SetMintbtn("process");
    if(once){
      // let id = toast.loading("Checking balance....")
      let Nftbalance = await ContractCall.Current_NFT_Balance(owner, item);
      console.log("ownneerrsnftbusdasdynowbalittemmm", Nftbalance , owner?.NFTBalance , item.ContractType  , owner?.NFTOwner);

    
      if ((Number(Nftbalance) !== Number(owner?.NFTBalance) &&  item?.ContractType === '1155' ) || ( Nftbalance.toLowerCase() !=  owner?.NFTOwner.toLowerCase()  &&  item?.ContractType === '721') ) {
       
  
        toast.warning("You won't buy at this moment please refresh you data");
        setTimeout(() => {
          push("/");
        }, 1000);
      }
     
    }
    SetMintbtn("start");
    return ()=>{ setOnce(false)}
    
  }
  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show4}
        className="putonsale"
        scrollable={true}
      >
        <Modal.Header>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={()=>{
              closePop();
              onhide()}}
          ></button>
          <Modal.Title id="contained-modal-title-vcenter">
            {console.log("PUTSALETYPE", FormValue.PutOnSaleType,FormValue.PutOnSale)}
            {text == "Change Price" ? <>Change Price</> : <>  Put on Sale</>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="approvetransaction heightlist">
            <div className="item_imgs">
              <ImgAudVideo
                file={item.CompressedFile.split(':')[0] == 'https' ? item.CompressedFile : `${config.IMG_URL}/nft/${item.NFTCreator}/Compressed/NFT/${item.CompressedFile}`}
                type={
                  item.CompressedFile
                    ? item.CompressedFile?.includes(".webp") || item.CompressedFile?.includes(".png")
                      ? "image"
                      : item.CompressedFile.includes(".webm")
                        ? "video"
                        : "audio"
                    : item.CompressedFile
                }
                thumb={`${config.IMG_URL}/nft/${item.NFTCreator}/Compressed/NFT_THUMB/${item.CompressedThumbFile}`}
                from="info"
                origFile={`${config.IMG_URL}/nft/${item.NFTCreator}/Original/NFT/${item.OriginalImage}`}
                className="img-fluid"
              />
              {/* <img src={Image} alt="imgae" className='img-fluid'/> */}
            </div>
            <div className="putonsale">
              <p className="putonsalede mt-4">
                It is a long established <span>fact</span> that a reader will be
                <span>0x02d54f...</span> distracted by the readable content
              </p>
            </div>
            <div className="putonsale">
              <div>
                <h2 className="putonsasle">{text == "Change Price" ? <>Change Price</> : <>  Put on Sale</>}</h2>
                {/* <p className='receives'>You’ll receive bids on this item</p> */}
              </div>
              {/* <div className="toggleswitch">
                                  <button className="switch toggle_custom" onClick={() =>setShow4(!show4)}><input type="checkbox" id="putonsale" name="putonsale"  /><span  className={show4?"slider":"overleft"} ></span></button>
                                  </div> */}
            </div>



            {FormValue?.PutOnSale && (
              <>
                <div className="list_for_sale_now d-block d-sm-flex justify-content-sm-between hy new_hy">
                  <div
                    className={
                      FormValue?.PutOnSaleType ==
                        "FixedPrice"
                        ? "list_border active fixed_price d-flex justify-content-start align-items-center"
                        : "list_border fixed_price d-flex justify-content-start align-items-center"
                    }
                    onClick={() =>
                      SetFormValue({
                        ...FormValue,
                        ...{
                          ["PutOnSaleType"]: "FixedPrice",
                        },
                      })
                    }
                  >
                    <div className="icon">
                      <i
                        class="fa fa-tag"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <span className="fixed">Fixed Price</span>
                  </div>
                  {(String(FormValue?.ContractType) === '721' && text != "Change Price")&&
                    <div
                      className={
                        FormValue?.PutOnSaleType ==
                          "TimedAuction"
                          ? "list_border active timed_auction d-flex justify-content-start align-items-center"
                          : "list_border timed_auction d-flex justify-content-start align-items-center"
                      }
                      onClick={() =>{
                        SetFormValue({
                          ...FormValue,
                          ...{
                            ["PutOnSaleType"]: "TimedAuction",
                            ["CoinName"]:  currency?.filter(
                              (item) => item.address != config.DEADADDRESS
                            )[0].label 
                          },
                        })
                      

                        console.log("datainlabel",  currency?.filter(
                          (item) => item.address != config.DEADADDRESS
                        )[0].label )
                      }
                        
                      }
                    >
                      <div className="icon">
                        <i
                          class="fa fa-clock"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <span className="fixed">Timed Auction</span>
                    </div>}
                </div>

                <div className="set-item1 my-5">
                  <fieldset>
                    <label className="mb8 text-white">
                      {FormValue.PutOnSaleType ==
                        "FixedPrice"
                        ? "Fixed Price"
                        : "Minimum Bid"}
                    </label>
                    <div className="d-flex justify-content-between align-items-center fixedborder">
                      <input
                        type="text"
                        className="set-items"
                        id="NFTPrice"
                        onChange={onChange}
                        value={FormValue.NFTPrice}
                        placeholder="e.g.10"
                        autoComplete="off"
                        maxLength={7}
                      />
                      <Select
                        // menuIsOpen={true} 
                        onChange={(e) =>{
                          SetBtnData('start');
                          SetFormValue({
                            ...FormValue,
                            ...{ ["CoinName"]: e.label },
                          })
                        }
                          
                        }
                        styles={stylesgraybg}
                        // menuIsOpen={true}
                        value={
                     
                               {
                                label: FormValue.CoinName,
                                value: FormValue.CoinName,
                              }
                          

                        }
                        // defaultValue={
                        //   NFTFormValue.PutOnSaleType
                        //     ? NFTFormValue.PutOnSaleType == "FixedPrice"
                        //       ? {
                        //         label: NFTFormValue.CoinName,
                        //         value: NFTFormValue.CoinName,
                        //       }
                        //       : currency.filter(
                        //         (item) => item.value !== "BNB"
                        //       )[0]
                        //     : currency[0]
                        // }
                        options={
                          FormValue.PutOnSaleType ==
                            "FixedPrice"
                            ? currency?.filter(
                              (item) => item.deleted != true
                            )
                            :
                            currency?.filter(
                              (item) => item.address != config.DEADADDRESS
                            )

                        }
                        classNamePrefix="react-select"
                        isSearchable={false}
                      />
                    </div>
                    <div className="d-flex justify-content-between align-items-center pt-2">
                      {ValidateError.NFTPrice && (
                        <span className="text-danger img-file">
                          {ValidateError.NFTPrice}
                        </span>
                      )}
                      {ValidateError.CoinName && (
                        <span className="text-danger img-file">
                          {ValidateError.CoinName}
                        </span>
                      )}
                    </div>
                  </fieldset>
                  {FormValue?.PutOnSaleType ==
                    "FixedPrice" ? (
                    <fieldset className="mt-4">
                      <div className="d-flex justify-content-between align-items-center">
                      <p className="mt-1">
                        Service Fees</p>
                        <b className="text-white">{web3?.utils?.fromWei(sellerFees)}%
                      </b>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                      <p className="mt-1">
                        You will receive</p>
                        <b className="text-white"> {Number(YouWillGet).toFixed(6)} {FormValue.CoinName}
                        {/* <i
                          class="fa fa-usd"
                          aria-hidden="true"
                        ></i> */}
                      </b>
                      </div>
                      
                    </fieldset>
                  ) : (
                    <fieldset>
                      <div className="my-3 d-block d-sm-flex align-items-center justify-content-sm-between">
                        <div className="starting_date">
                          <label className="text-white"> Starting Date</label>
                          <Select
                            value={{
                              value:FormValue.ClockTime && moment(FormValue.ClockTime).format("YYYY-MM-DD h:mm:ss a"),
                              label: FormValue.ClockTime &&  moment(FormValue.ClockTime).format("YYYY-MM-DD h:mm:ss a"),
                            }}
                            onChange={(e) =>
                              DateSelection(e, "start")
                            }
                            options={startingdate}
                            classNamePrefix="react-select"
                            isSearchable={false}
                          />
                          {ValidateError.ClockTime && (
                            <span className="text-danger img-file">
                              {ValidateError.ClockTime}
                            </span>
                          )}
                        </div>
                        <div className="ending_date">
                          <label className="text-white"> Ending Date</label>
                          {console.log("ENDCLOCKTIME", FormValue.EndClockTime)}
                          <Select
                            value={{
                              value:
                                FormValue.EndClockTime && moment(FormValue.EndClockTime).format("YYYY-MM-DD h:mm:ss a"),
                              label:
                              FormValue.EndClockTime && moment(FormValue.EndClockTime).format("YYYY-MM-DD h:mm:ss a"),
                            }}
                            onChange={(e) =>
                              DateSelection(e, "end")
                            }
                            options={enddate}
                            classNamePrefix="react-select"
                            isSearchable={false}
                          />
                          {ValidateError.EndClockTime && (
                            <span className="text-danger img-file">
                              {ValidateError.EndClockTime}
                            </span>
                          )}
                        </div>
                      </div>
                    </fieldset>
                  )}
                </div>
              </>
            )}



            <div className={show4 ? "putonsalefixed" : "tabbable-line"}>
              {/* <ul className="nav nav-tabs ">
                      <li className="active">
                          <a href="#fixedprice" data-toggle="tab">
                          <div className="fixedprice">
                                      <i className="fal fa-dollar-sign"></i>
                                      <p>Fixed Price</p>
                                  </div></a>
                      </li>
                      <li>
                          <a href="#timeauction" data-toggle="tab">
                              <div className="fixedprice">
                                      <i className="fas fa-clock"></i>
                                      <p>Timed Auction</p>
                                  </div></a>
                      </li>
                  </ul> */}
              <div class="tab-content mt-5">
                <div class="tab-pane active" id="fixedprice">
                  <div className="fixedpricedropdown mb-4">
                    <div className="formgroupsec text-left">
                      {/* <label className="text-left mb-3 receives">Enter your Price</label> */}
                      <div className="fixedpricedropdownflex">
                        {/* <input
                          type="text"
                          autoComplete="off"
                          className=""
                          placeholder="e.g.10"
                          onChange={onChange}
                          id="NFTPrice"
                          value={FormValue.NFTPrice}
                          maxLength={7}
                        /> */}
                        {/* {(FormValue.PutOnSaleType == "FixedPrice" ||
                          FormValue.PutOnSaleType == "TimedAuction") &&
                          ValidateError.NFTPrice && (
                            <div className="error_msg">
                              {ValidateError.NFTPrice}
                            </div>
                          )} */}
                        {/* <div></div> */}
                        <div className="mt-5 coins">
                          {/* <label className="text-left mb-1 receives">Choose Coin Type</label> */}
                          {/* <Select
                          // menuIsOpen={true} 
                            defaultValue={selectedOption}
                            isSearchable={false}
                            onChange={(e) => onSelectChange(e, "price")}
                            // value={
                            //   FormValue.PutOnSaleType
                            //     ? FormValue.PutOnSaleType == "FixedPrice"
                            //       ? {
                            //           label: FormValue.CoinName,
                            //           value: FormValue.CoinName,
                            //         }
                            //       : currency.filter(
                            //           (item) => item.value !== "BNB"
                            //         )[0]
                            //     : currency[0]
                            // }
                            options={
                              FormValue.PutOnSaleType == "FixedPrice"
                                ? currency.filter((item)=> item.deleted != true)
                                : currency.filter((item) => item.address != config.DEADADDRESS)
                            }
                            id="CoinName"
                            classNamePrefix="react-select"
                          /> */}
                        </div>
                        {/* {(FormValue.PutOnSaleType == "FixedPrice" ||
                          FormValue.PutOnSaleType == "TimedAuction") &&
                          ValidateError.CoinName && (
                            <div className="error_msg">
                              {ValidateError.CoinName}
                            </div>
                          )} */}
                      </div>
                      {/* <p className="pt-4">Service fee1% You will receive 0XDC $( 0)</p> */}
                    </div>
                    {/* {
                      (FormValue.ContractType === 1155 || FormValue.ContractType === "1155" ) &&
                    <div className="formgroupsec text-left">
                      <label className="text-left mb-3 receives">Enter Quantity</label>
                      <div className="fixedpricedropdownflex">
                      <input
                          type="text"
                          autoComplete="off"
                          className=""
                          placeholder="e.g.10"
                          onChange={onChange}
                          id="NFTQuantity"
                          value={FormValue.NFTQuantity}
                        />
                        {(FormValue.PutOnSaleType == "FixedPrice" ||
                          FormValue.PutOnSaleType == "TimedAuction") &&
                          ValidateError.NFTQuantity && (
                            <div className="error_msg">
                              {ValidateError.NFTQuantity}
                            </div>
                          )}
                        </div>
                        </div>
                      } */}
                  </div>
                </div>
                <div class="tab-pane" id="timeauction">
                  <div className="minimumbiddropdown mb-4">
                    <div className="formgroupsec">
                      <label className="text-white">Minimum bid</label>
                      <div className="fixedpricedropdownflex">
                        <div>
                          <input
                            className=""
                            type="text"
                            placeholder="Enter minimum bid"
                          />
                        </div>
                        <div>
                          <p>BLKZ</p>
                        </div>
                      </div>
                      <p>
                        Bids below this amount won't be allowed. If you not
                        enter any amount we will consider as 0
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {FormValue.PutOnSaleType == "FixedPrice" && (
              <>
              <div className="d-flex justify-content-between">
                <div className="servicefee">
                  <p>Service Fee {web3.utils.fromWei(String(sellerFees))} %</p>
                </div>
                <div className="servicefee">
                  <p>You will Get {YouWillGet ?? 0}</p>
                </div>
                </div>
              </>
            )} */}

            <div className="connecwalletbtns mt-5 d-flex justify-content-between">
            {console.log("BtnData",BtnData)}
              <Button
                className="connectwallet secondary"
                onClick={BtnData == "start" ? FormSubmit : null}
              >
                {(BtnData == "start" || BtnData == "error") && text == "Change Price" ? <>Change Price</> : <>  Put on Sale</>}
                {/* {BtnData == "error" && "Put on Sale"} */}
                {BtnData == " process" && " processing"}
                {BtnData == "done" && "Done"}
              </Button>
              <button className="cancelpayment primary" onClick={closePop}>
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show8}
      >
        <Modal.Header>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={closePop}
          ></button>
          <Modal.Title id="contained-modal-title-vcenter">
            Follow Steps
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {BtnData == "open" && (
            <div className="ifpsmetadata">
              <div className="ifpsmetadatasec  d-flex justify-content-start align-items-center">
                <i className="fa fa-check"></i>
                <div className="text-left ml-5">
                  <h6 className="receives">Approve Call</h6>
                  <p className="receives">This Process Take One Time Gas Fees</p>
                </div>
              </div>
              <Button
                className="connectwallet primary my-3"
                disabled={
                  TokenBtn == "process" || TokenBtn == "done" ? true : false
                }
                onClick={
                  TokenBtn == "start" || TokenBtn == "try"
                    ? TokenApproveCall
                    : null
                }
                disableRipple
              >
                {TokenBtn == "start" && "Start"}
                {TokenBtn == "process" && "In-Progress"}
                {TokenBtn == "try" && "Try-Again"}
                {TokenBtn == "done" && "Done"}
              </Button>
            </div>
          )}

          <div className="ifpsmetadata mt-4">
            <div className="ifpsmetadatasec d-flex justify-content-start align-items-center">
              <i className="fa fa-check"></i>
              <div className="text-left ml-5">
                <h6 className="receives">{text == "Change Price" ? <>Change Price</> : <>  Put on Sale</>}</h6>
                <p className="receives">Call contract method</p>
              </div>
            </div>
            <Button
              className="connectwallet primary my-3"
              disabled={
                Mintbtn == "process" || Mintbtn == "done" ? true : false
              }
              onClick={Mintbtn == "start" || Mintbtn == "try" ? ListCall : ""}
            >
              {Mintbtn == "start" && "Start"}
              {Mintbtn == "process" && "In-Progress"}
              {Mintbtn == "try" && "Try-Again"}
              {Mintbtn == "done" && "Done"}
              {Mintbtn == "init" && "Start"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {((OpenPopup == "ClockTime" ||
        OpenPopup == "EndClockTime") && (
          <CLockModal
            SetOpenPopup={SetOpenPopup}
            modal={OpenPopup}
            validDate={OpenPopup == "ClockTime" ? validStart : validEnd}
            setClockValue={setClockValue}
            closeModal={CloseModal}
          />
        ))}

    </>
  );
}
